<template>
	<NuxtLoadingIndicator :color="'var(--highlight-bg)'" />

	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>
</template>

<style>
	.nuxt-icon svg {
		margin-bottom: 0;
	}
</style>
